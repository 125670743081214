define("discourse/plugins/discourse-calendar/discourse/controllers/discourse-post-event-bulk-invite", ["exports", "@ember/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "discourse-common/utils/decorators", "discourse/mixins/modal-functionality", "@ember/controller", "discourse/models/group", "I18n", "@ember/service"], function (_exports, _utils, _ajax, _ajaxError, _object, _decorators, _modalFunctionality, _controller, _group, _I18n, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.observes)("bulkInvites.@each.identifier"), (_obj = {
    dialog: (0, _service.inject)(),
    bulkInvites: null,
    bulkInviteStatuses: null,
    bulkInviteDisabled: true,
    init() {
      this._super(...arguments);
      this.set("bulkInviteStatuses", [{
        label: _I18n.default.t("discourse_post_event.models.invitee.status.unknown"),
        name: "unknown"
      }, {
        label: _I18n.default.t("discourse_post_event.models.invitee.status.going"),
        name: "going"
      }, {
        label: _I18n.default.t("discourse_post_event.models.invitee.status.not_going"),
        name: "not_going"
      }, {
        label: _I18n.default.t("discourse_post_event.models.invitee.status.interested"),
        name: "interested"
      }]);
    },
    onShow() {
      this.set("bulkInvites", [_object.default.create({
        identifier: null,
        attendance: "unknown"
      })]);
    },
    groupFinder(term) {
      return _group.default.findAll({
        term,
        ignore_automatic: true
      });
    },
    setBulkInviteDisabled() {
      this.set("bulkInviteDisabled", this.bulkInvites.filter(x => (0, _utils.isPresent)(x.identifier)).length === 0);
    },
    sendBulkInvites() {
      return (0, _ajax.ajax)(`/discourse-post-event/events/${this.model.eventModel.id}/bulk-invite.json`, {
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          invitees: this.bulkInvites.filter(x => (0, _utils.isPresent)(x.identifier))
        })
      }).then(data => {
        if (data.success) {
          this.send("closeModal");
        }
      }).catch(e => this.flash((0, _ajaxError.extractError)(e), "error"));
    },
    removeBulkInvite(bulkInvite) {
      this.bulkInvites.removeObject(bulkInvite);
      if (!this.bulkInvites.length) {
        this.set("bulkInvites", [_object.default.create({
          identifier: null,
          attendance: "unknown"
        })]);
      }
    },
    addBulkInvite() {
      const attendance = this.bulkInvites.get("lastObject.attendance") || "unknown";
      this.bulkInvites.pushObject(_object.default.create({
        identifier: null,
        attendance
      }));
    },
    uploadDone() {
      this.send("closeModal");
      this.dialog.alert(_I18n.default.t("discourse_post_event.bulk_invite_modal.success"));
    },
    updateInviteIdentifier(bulkInvite, selected) {
      bulkInvite.set("identifier", selected.firstObject);
    }
  }, (_applyDecoratedDescriptor(_obj, "groupFinder", [_object.action], Object.getOwnPropertyDescriptor(_obj, "groupFinder"), _obj), _applyDecoratedDescriptor(_obj, "setBulkInviteDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "setBulkInviteDisabled"), _obj), _applyDecoratedDescriptor(_obj, "sendBulkInvites", [_object.action], Object.getOwnPropertyDescriptor(_obj, "sendBulkInvites"), _obj), _applyDecoratedDescriptor(_obj, "removeBulkInvite", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeBulkInvite"), _obj), _applyDecoratedDescriptor(_obj, "addBulkInvite", [_object.action], Object.getOwnPropertyDescriptor(_obj, "addBulkInvite"), _obj), _applyDecoratedDescriptor(_obj, "uploadDone", [_object.action], Object.getOwnPropertyDescriptor(_obj, "uploadDone"), _obj), _applyDecoratedDescriptor(_obj, "updateInviteIdentifier", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateInviteIdentifier"), _obj)), _obj)));
});