define("discourse/plugins/discourse-calendar/discourse/routes/discourse-post-event-upcoming-events-index", ["exports", "discourse/lib/url", "@ember/routing/route", "@ember/object/evented"], function (_exports, _url, _route, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    enforcePostEventEnabled: (0, _evented.on)("activate", function () {
      if (!this.siteSettings.discourse_post_event_enabled) {
        _url.default.redirectTo("/404");
      }
    }),
    model(params) {
      return this.store.findAll("discourse-post-event-event", params);
    }
  });
});