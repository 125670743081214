define("discourse/plugins/discourse-calendar/discourse/initializers/disable-sort", ["exports", "discourse-common/utils/decorators", "discourse/lib/plugin-api"], function (_exports, _decorators, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "disable-sort",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        var _dec, _obj;
        api.modifyClass("component:topic-list", (_dec = (0, _decorators.default)("category", "siteSettings.disable_resorting_on_categories_enabled"), (_obj = {
          pluginId: "discourse-calendar",
          sortable(category, disable_resorting_on_categories_enabled) {
            let disableSort = false;
            if (disable_resorting_on_categories_enabled && category?.custom_fields) {
              disableSort = !!category.custom_fields["disable_topic_resorting"];
            }
            return !!this.changeSort && !disableSort;
          }
        }, (_applyDecoratedDescriptor(_obj, "sortable", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortable"), _obj)), _obj)));
      });
    }
  };
});