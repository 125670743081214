define("discourse/plugins/discourse-calendar/discourse/lib/event-local-date", ["exports", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format"], function (_exports, _guessBestDateFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = eventLocalDate;
  function eventLocalDate(container) {
    container.classList.remove("past", "current", "future");
    container.innerHTML = "";
    const startsAt = moment.utc(container.dataset.starts_at).tz(moment.tz.guess());
    const endsAt = moment.utc(container.dataset.ends_at).tz(moment.tz.guess());
    const format = (0, _guessBestDateFormat.default)(startsAt);
    let title = startsAt.format(format);
    if (endsAt) {
      title += ` → ${endsAt.format(format)}`;
    }
    container.setAttribute("title", title);
    container.classList.add("past");
    container.innerText = startsAt.format(format);
  }
});