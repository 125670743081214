define("discourse/plugins/discourse-calendar/discourse/components/region-input", ["exports", "@ember/object", "discourse/plugins/discourse-calendar/discourse/lib/regions", "I18n", "select-kit/components/combo-box"], function (_exports, _object, _regions, _I18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    pluginApiIdentifiers: ["timezone-input"],
    classNames: ["timezone-input", "region-input"],
    allowNoneRegion: false,
    selectKitOptions: {
      filterable: true,
      allowAny: false
    },
    content: (0, _object.computed)(function () {
      const localeNames = {};
      let regions = [];
      JSON.parse(this.siteSettings.available_locales).forEach(locale => {
        localeNames[locale.value] = locale.name;
      });
      if (this.allowNoneRegion === true) {
        regions.push({
          name: _I18n.default.t("discourse_calendar.region.none"),
          id: null
        });
      }
      regions = regions.concat(_regions.HOLIDAY_REGIONS.map(region => ({
        name: _I18n.default.t(`discourse_calendar.region.names.${region}`),
        id: region
      })).sort((a, b) => a.name.localeCompare(b.name)));
      return regions;
    })
  });
});