define("discourse/plugins/discourse-calendar/discourse/initializers/add-event-ui-builder", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal"], function (_exports, _pluginApi, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeEventBuilder(api) {
    const currentUser = api.getCurrentUser();
    const store = api.container.lookup("service:store");
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        const eventModel = store.createRecord("discourse-post-event-event");
        eventModel.set("status", "public");
        eventModel.set("custom_fields", {});
        eventModel.set("starts_at", moment());
        eventModel.set("timezone", moment.tz.guess());
        (0, _showModal.default)("discourse-post-event-builder").setProperties({
          toolbarEvent,
          model: {
            eventModel
          }
        });
      },
      group: "insertions",
      icon: "calendar-day",
      label: "discourse_post_event.builder_modal.attach",
      condition: composer => {
        if (!currentUser || !currentUser.can_create_discourse_post_event) {
          return false;
        }
        const composerModel = composer.model;
        return composerModel && !composerModel.replyingToTopic && (composerModel.topicFirstPost || composerModel.creatingPrivateMessage || composerModel.editingPost && composerModel.post && composerModel.post.post_number === 1);
      }
    });
  }
  var _default = _exports.default = {
    name: "add-discourse-post-event-builder",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", initializeEventBuilder);
      }
    }
  };
});