define("discourse/plugins/discourse-calendar/discourse/initializers/decorate-topic-title", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-calendar/discourse/lib/event-relative-date", "discourse/plugins/discourse-calendar/discourse/lib/event-local-date"], function (_exports, _pluginApi, _eventRelativeDate, _eventLocalDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeDecorateTopicTitle(api) {
    api.decorateTopicTitle((topic, node, topicTitleType) => {
      const container = node.querySelector(".event-date-container");
      container && container.remove();
      if (!topic.event_starts_at || !topic.event_ends_at) {
        return;
      }
      if (topicTitleType === "topic-list-item-title" || topicTitleType === "header-title") {
        const eventdateContainer = document.createElement("div");
        eventdateContainer.classList.add("event-date-container");
        const eventDate = document.createElement("span");
        eventDate.classList.add("event-date", "event-relative-date");
        eventDate.dataset.starts_at = topic.event_starts_at;
        eventDate.dataset.ends_at = topic.event_ends_at;
        eventdateContainer.appendChild(eventDate);
        node.appendChild(eventdateContainer);
        if (topic.siteSettings.use_local_event_date) {
          (0, _eventLocalDate.default)(eventDate);
        } else {
          // we force a first computation, as waiting for the auto update might take time
          (0, _eventRelativeDate.default)(eventDate);
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "decorate-topic-title",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.40", initializeDecorateTopicTitle);
      }
    }
  };
});