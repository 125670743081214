define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezones-slider", ["exports", "discourse/widgets/widget", "@ember/runloop"], function (_exports, _widget, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezones-slider", {
    tagName: "input.group-timezones-slider",
    input(event) {
      this._handleSliderEvent(event);
    },
    change(event) {
      this._handleSliderEvent(event);
    },
    changeOffsetThrottler(offset) {
      (0, _runloop.throttle)(this, function () {
        this.sendWidgetAction("onChangeCurrentUserTimeOffset", offset);
      }, 75);
    },
    buildAttributes() {
      return {
        step: 1,
        value: 0,
        min: -48,
        max: 48,
        type: "range"
      };
    },
    _handleSliderEvent(event) {
      const value = parseInt(event.target.value, 10);
      const offset = value * 15;
      this.changeOffsetThrottler(offset);
    }
  });
});