define("discourse/plugins/discourse-calendar/discourse/controllers/discourse-post-event-invitees", ["exports", "discourse/mixins/modal-functionality", "@ember/controller", "@ember/object", "discourse-common/lib/debounce"], function (_exports, _modalFunctionality, _controller, _object, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_obj = {
    invitees: null,
    filter: null,
    isLoading: false,
    type: "going",
    onShow() {
      this._fetchInvitees();
    },
    toggleViewingFilter(filter) {
      this.onFilterChanged(filter);
    },
    toggleType(type) {
      this.set("type", type);
      this._fetchInvitees(this.filter);
    },
    onFilterChanged(filter) {
      (0, _debounce.default)(this, this._fetchInvitees, filter, this.type, 250);
    },
    removeInvitee(invitee) {
      invitee.destroyRecord().then(() => this._fetchInvitees());
    },
    _fetchInvitees(filter) {
      this.set("isLoading", true);
      this.store.findAll("discourse-post-event-invitee", {
        filter,
        post_id: this.model.id,
        type: this.type
      }).then(invitees => this.set("invitees", invitees)).finally(() => this.set("isLoading", false));
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleViewingFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleViewingFilter"), _obj), _applyDecoratedDescriptor(_obj, "toggleType", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleType"), _obj), _applyDecoratedDescriptor(_obj, "onFilterChanged", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onFilterChanged"), _obj), _applyDecoratedDescriptor(_obj, "removeInvitee", [_object.action], Object.getOwnPropertyDescriptor(_obj, "removeInvitee"), _obj)), _obj));
});