define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-group-timezones-filter", ["exports", "I18n", "discourse/widgets/widget", "@ember/runloop"], function (_exports, _I18n, _widget, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-group-timezones-filter", {
    tagName: "input.group-timezones-filter",
    input(event) {
      this.changeFilterThrottler(event.target.value);
    },
    changeFilterThrottler(filter) {
      (0, _runloop.throttle)(this, function () {
        this.sendWidgetAction("onChangeFilter", filter);
      }, 100);
    },
    buildAttributes() {
      return {
        type: "text",
        placeholder: _I18n.default.t("group_timezones.search")
      };
    }
  });
});