define("discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event-url", ["exports", "discourse-common/lib/icon-library", "virtual-dom", "discourse/widgets/widget"], function (_exports, _iconLibrary, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function prefixProtocol(url) {
    return url.indexOf("://") === -1 && url.indexOf("mailto:") === -1 ? "https://" + url : url;
  }
  var _default = _exports.default = (0, _widget.createWidget)("discourse-post-event-url", {
    tagName: "section.event-url",
    html(attrs) {
      return [(0, _iconLibrary.iconNode)("link"), (0, _virtualDom.h)("a.url", {
        attributes: {
          href: prefixProtocol(attrs.url),
          target: "_blank",
          rel: "noopener noreferrer"
        }
      }, attrs.url)];
    }
  });
});